<template>
  <div>
    <v-modal-posts-insert v-model="dialogInsert"></v-modal-posts-insert>
    <v-modal-posts-delete v-model="dialogDelete" :id="selectedIndex"></v-modal-posts-delete>
    <v-modal-posts-update v-model="dialogUpdate" :id="selectedIndex"></v-modal-posts-update>

    <v-page-title title="Knowledge Base">
      <v-btn 
        elevation="0" 
        color="primary" 
        small 
        dark 
        @click="dialogInsert = true"
      >
        <v-icon left small>mdi-plus</v-icon>
        New Post
      </v-btn>
    </v-page-title>

    <v-card max-width="1200px" class="mx-auto my-5" elevation="0" color="transparent">
      <v-posts-breadcrumbs :category_name="category.name" :category_id="category.id"></v-posts-breadcrumbs>
    </v-card>

    <v-card max-width="1200px" class="mx-auto my-5" elevation="0" v-if="!category">
      <v-loader></v-loader> 
    </v-card>
    
    <v-card max-width="1200px" class="mx-auto my-5" elevation="0" v-else>
      <v-card-title>
        <section>
          <h2 class="mb-0">{{ getCategoryName() }}</h2>
        </section>
      </v-card-title>
      <v-card-text class="black--text">
        <v-loader v-if="!posts"></v-loader>
        <div class="hounddog-table" v-else>
          <v-data-table
            item-key="id"
            :headers="headers" 
            :items="posts" 
            :items-per-page=1000000
            no-data-text="No posts yet."
          >
            <template v-slot:item.icon="{}">
              <v-icon color="#CFD8DC" class="pr-2">{{scanIcon}}</v-icon>
            </template>

            <template v-slot:item.title="{ item }">
              <v-btn plain text elevation="0" class="pa-0" :to="`/knowledge-base/post/${item.id}`">
                <b>{{ item.title }}</b>
              </v-btn>
            </template>
            <template v-slot:item.author="{ item }">
              <b>{{ item.user_name }}</b><br>
            </template>

            <template v-slot:item.created_at="{item }">
              {{ $date.fullDateTime(item.created_at, 30) }}
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn-toggle mandatory background-color="primary" class="ml-2">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn :to="`/knowledge-base/post/${item.id}`" small color="primary" icon v-bind="attrs" v-on="on">
                      <v-icon small color="white">mdi-magnify</v-icon>
                    </v-btn>
                  </template>
                  <span>View Post</span>
                </v-tooltip>
                <!-- <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn  @click="deleteDialog(item)" small color="primary" icon v-bind="attrs" v-on="on">
                      <v-icon small color="white">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete Post</span>
                </v-tooltip> -->
              </v-btn-toggle>
            </template>
            
          </v-data-table>
        </div>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
  export default {
    name: 'Category.vue',
    data() {
      return {
        scanIcon: "mdi-application-brackets-outline",
        selectedIndex: 0,
        dialogInsert: false,
        dialogDelete: false,
        dialogUpdate: false,
        category: {},
        posts: [],
        headers: [
          { text: "", value: "icon", width: "40px" },
          { text: "Title", value: "title", divider: true, align: 'left' },
          { text: "Author", value: "author", divider: true, align: 'left', width: '200px' },
          { text: "Created On", value: "created_at", divider: true, align: 'right', width: '250px' },
          { text: "Actions", value: "actions", divider: true, width: '100px', align: 'center' },
        ],
      }
    },
    async created(){
      this.selectedIndex = this.id;
      if(!this.$utilities.isEmpty(this.id)){
        await this.getCategory();
        await this.getPosts();
      }else{
        this.$router.push({name: 'Knowledge Base'});
      }
    },
    computed:{
      user(){
        return this.$store.getters['users/user'];
      },
      id(){
        return this.$route.params.id
      },
      isEditable(){
        return this.user.super;
      },
    },
    methods: {
      async getCategory(){
        this.category = await this.$store.dispatch('categories/get', {id: this.id});
      },
      getCategoryName(){
        if(this.category.name){
          return this.category.name;
        }else{
          return 'Loading...';
        }
      },
      async getPosts(){
        var self = this;
        return this.$store.dispatch('posts/category', {category_id: this.id})
          .then((results) => {
            self.posts = results;
          });
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>